<template>
  <div :class="$style.wrap">
    <div :class="$style.up">
      <div :class="$style.productInfo">
        <img :class="$style.cover" :src="info.image_url" />
        <div :class="$style.owner">
          <p :class="$style.ownerNam">{{ info.ddc_name }}</p>
          <div :class="$style.info">
            <img :class="$style.avatar" :src="info.artist_headimg" />
            <span :class="$style.name">{{ info.artist_name }}</span>
          </div>
        </div>
      </div>
      <div
        :class="[
          $style.status,
          {
            [$style.success]: Number(info.pay_status) === 2,
            [$style.pending]: Number(info.pay_status) === 1,
          },
        ]"
      >
        <p>{{ payStatusMap[info.pay_status] }}</p>
        <p v-if="Number(info.pay_status) === 1" :class="$style.remain">
          剩余时间：{{ formatTime(countdown) }}
        </p>
      </div>
    </div>
    <div :class="$style.down">
      <p :class="$style.line">
        <span>交易金额</span>
        <span>{{ info.total_fee }}元</span>
      </p>
      <p :class="$style.line">
        <span>{{ info.pay_status | timeWriting }}时间</span>
        <span>{{ timeValue }}</span>
      </p>
      <p :class="$style.line">
        <span>记录单号</span>
        <span>{{ info.order_no }}</span>
      </p>
    </div>
    <div v-if="Number(info.pay_status) === 1" :class="$style.btnWrap">
      <span :class="$style.btn" @click="handleCancel">取消</span>
      <span :class="[$style.btn, $style.primary]" @click="handlePay">支付</span>
    </div>
  </div>
</template>

<script>
import { cancelOrder } from "@/api";
import { isInWX } from "@/utils/util";
// 订单列表状态：对应的文案和字段
// 1: "待支付",【创建时间】：created_at
// 2: "已支付",【交易时间】：paid_at
// 3: "已取消",【取消时间】：cancel_at
// 4: "已取消",【取消时间】：cancel_at
export default {
  filters: {
    // 时间文案显示
    timeWriting(status) {
      const map = {
        1: "创建",
        2: "交易",
        3: "取消",
        4: "取消",
      };
      return map[status];
    },
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      payStatusMap: {
        1: "待支付",
        2: "已支付",
        3: "已取消",
        4: "已取消",
      },
      countdown: "",
      timer: null,
    };
  },
  computed: {
    timeValue() {
      const { pay_status } = this.info;
      const map = {
        1: "created_at",
        2: "paid_at",
        3: "cancel_at",
        4: "cancel_at",
      };
      return this.info[map[pay_status]];
    },
  },
  created() {
    this.initTimer();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    initTimer() {
      const { pay_status, remain_second } = this.info;
      // 待支付倒计时
      if (Number(pay_status) === 1 && remain_second > 0) {
        this.countdown = remain_second;
        this.timer = setInterval(() => {
          if (this.countdown > 1) {
            this.countdown--;
          } else {
            clearInterval(this.timer);
            location.reload();
          }
        }, 1000);
      }
    },
    formatTime(second) {
      let min = parseInt(second / 60);
      let sec = second % 60;
      min = min === 0 ? "00" : `0${min}`;
      sec = sec < 10 ? `0${sec}` : sec;
      return `${min}:${sec}`;
    },
    handleCancel() {
      this.$dialog
        .confirm({
          title: "",
          message: "确认取消本次订单支付？",
        })
        .then(() => {
          const data = {
            order_no: this.info.order_no,
          };
          cancelOrder(data).then(() => {
            this.$toast.success("取消订单成功");
            location.reload();
          });
        })
        .catch(() => {});
    },
    handlePay() {
      // payment_state: 支付环境:1-微信外,2-微信内,3-支付宝H5,4-支付宝APP
      const isWx = isInWX();
      const { payment_state } = this.info;
      if (payment_state === 3) {
        // 支付宝支付
        this.handlePayAli();
        return;
      }
      if (payment_state === 1 && isWx) {
        this.$toast("该订单请在微信外浏览器进行支付");
        return;
      }
      if (payment_state === 2 && !isWx) {
        this.$toast("该订单请在微信内进行支付");
        return;
      }

      if (isWx) {
        this.handlePayInWx();
      } else {
        this.handlePayOutWx();
      }
    },
    // 微信内支付
    handlePayInWx() {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            // eslint-disable-next-line no-undef
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          // eslint-disable-next-line no-undef
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          // eslint-disable-next-line no-undef
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        this.onBridgeReady();
      }
    },
    onBridgeReady() {
      const { appId, timeStamp, nonceStr, signType, paySign } =
        this.info.h5_url;
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId, //公众号名称，由商户传入
          timeStamp, //时间戳，自1970年以来的秒数
          nonceStr, //随机串
          package: this.info.h5_url.package,
          signType, //微信签名方式;
          paySign, //微信签名
        },
        (res) => {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            //do something
            this.$emit("refresh");
          } else {
            //支付失败处理
            this.$emit("refresh");
          }
        }
      );
    },
    // 微信外支付
    handlePayOutWx() {
      location.href = this.info.h5_url;
    },
    handlePayAli() {
      const div = document.createElement("div");
      /* 此处form就是后台返回接收到的数据 */
      div.innerHTML = this.info.h5_url;
      document.body.appendChild(div);
      document.forms[0].submit();
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  padding: 10px;
  border-radius: 14px;
  background: #212121;
  .up {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #343434;
    .productInfo {
      display: flex;
      .cover {
        width: 89px;
        height: 89px;
        border-radius: 12px;
        margin-right: 17px;
      }
      .owner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 115px;
        font-weight: 500;
        .ownerNam {
          line-height: 20px;
          @include ellipsis(2);
        }
        .info {
          display: flex;
          align-items: center;
          .avatar {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin-right: 5px;
          }
          .name {
            font-size: 12px;
            color: #b9b9b9;
            @include ellipsis(1);
          }
        }
      }
    }
    .status {
      line-height: 20px;
      color: #e6e8ea;
      text-align: right;
      &.success {
        color: #3894df;
      }
      &.pending {
        color: #07c160;
      }
      .remain {
        height: 14px;
        font-size: 10px;
        color: #e6e8ea;
        line-height: 14px;
        margin-top: 4px;
      }
    }
  }
  .down {
    padding: 2px 0 7px;
    .line {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
    }
  }
  .btnWrap {
    display: flex;
    justify-content: flex-end;
    padding-top: 11px;
    border-top: 1px solid #343434;
    .btn {
      width: 76px;
      height: 30px;
      box-sizing: border-box;
      line-height: 28px;
      text-align: center;
      border-radius: 15px;
      border: 1px solid #ffffff;
      &.primary {
        background: #ffffff;
        border: 1px solid #ffffff;
        color: #000;
        margin-left: 10px;
      }
    }
  }
}
</style>
