// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index_list_1iliK{padding:0 .53333rem}.index_list_1iliK .index_listItem_31FHe{margin-top:.61333rem}", ""]);
// Exports
exports.locals = {
	"list": "index_list_1iliK",
	"listItem": "index_listItem_31FHe"
};
module.exports = exports;
