<template>
  <div>
    <Back title="我的订单" redirect="/personal" />
    <van-list
      :class="$style.list"
      v-model="loading"
      :finished="finished"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <OrderCard
        :class="$style.listItem"
        @refresh="refresh"
        v-for="item in list"
        :key="item.id"
        :info="item"
      />
    </van-list>
  </div>
</template>

<script>
import Back from "@/components/Back";
import OrderCard from "@/components/OrderCard";
import { fetchOrderList } from "@/api";
export default {
  components: {
    Back,
    OrderCard,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      error: false,
      pageSize: 15,
      page: 1,
      total: 0,
    };
  },
  mounted() {
    this.payCallBack();
  },
  methods: {
    payCallBack() {
      if (this.$route.query.refresh) {
        this.$dialog
          .confirm({
            title: "",
            confirmButtonText: "已完成",
            cancelButtonText: "未完成",
            message: "请确认支付是否完成",
          })
          .then(() => {
            // 此处通过href改变url，即去除路由中的refresh值，防止用户在刷新的时候再次出发此确认框
            window.location.href = "/order";
          })
          .catch(() => {
            window.location.href = "/order";
          });
      }
    },
    getList() {
      const data = {
        page_size: this.pageSize,
        page: this.page,
      };
      fetchOrderList(data)
        .then((res) => {
          const { list, total, page } = res;
          this.total = total;
          this.list = [...this.list, ...list];
          this.loading = false;
          this.page = page + 1;
          if (this.list.length >= this.total) {
            this.finished = true;
          }
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    },
    onLoad() {
      this.getList();
    },
    refresh() {
      this.$dialog
        .confirm({
          title: "",
          confirmButtonText: "已完成",
          cancelButtonText: "未完成",
          message: "请确认支付是否完成",
        })
        .then(() => {
          location.reload();
        })
        .catch(() => {
          location.reload();
        });
    },
  },
};
</script>

<style lang="scss" module>
.list {
  padding: 0 20px;
  .listItem {
    margin-top: 23px;
  }
}
</style>
